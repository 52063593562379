<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import Games from '../../components/setting/games'

export default {
  components: {
    Layout,
    PageHeader,
    Games
  },
  data() {
    return {
      title: this.$t('게임') + this.$t('설정'),
    };
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <Games />

  </Layout>
</template>
