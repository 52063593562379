<script>
import SelectVendors from "@/components/select-vendors"

import ModalAlert from '@/components/ui/modal-alert'

export default {
  components: {
    SelectVendors,
    ModalAlert
  },
  props: {
    user: Object
  },
  data() {
    return {
      search: {
        vendor: ''
      },
      list: [],
      limit: 1000,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
      vendors: [],
      oriVendors: [],
      categories: [],
      selectedCategory: '',
      searchType:'name_ko', //name, code
      searchText:'',
      searchGameName: '',
      sort: {
        field:'',
        directive:0
      }
    };
  },
  mounted() {

  },
  methods: {
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function() {

      //정렬을 초기화한다.
      this.sort.field = ''
      this.sort.directive = 0

      const loader = this.$loading.show({container: this.$refs.contentWrapper})
      try {
        const params = {
          search: this.search,
          sort: 'isLobby DESC, sort ASC',
          limit: this.limit,
          page: this.page,
          parent: this.user ? this.user.id : null
        }


        const result = await this.$API.setting.games(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(const key in result.documents) {
          const doc = result.documents[key]
          doc.active = doc.active === 1
          doc.ko = doc.names.ko
          doc.en = doc.names.en
          this.list.push(doc)
        }



        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount ? result.totalPageCount : 1
        this.page = result.page
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    searchGame: function() {

    },
    save: async function(game) {
      const loader = this.$loading.show({
        container: this.$refs.contentWrapper,
        canCancel: false
      })
      game.parent = this.user ? this.user.id : null
      try {
        console.log("저장하기 sort->", game.sort)
        // let params = JSON.parse(JSON.stringify(game))
        // params.active = params.active === true ? 1 : 0
        const result = await this.$API.setting.updateGame(game._id, game)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    updateSort: async function() {
      let IDs = []
      for(const key in this.list) {
        const record = this.list[key]
        IDs.push(record._id)
      }
    },
    searchVendors(id) {
      this.search.vendor = id
      this.searchList()
    },
    setSort(field){
      console.log("정렬->", field)

      if (this.sort.field == field) {

        if(this.sort.directive == 1) {
          console.log("내림차순")
          //오름차순이면 내림차순으로 바꾼다.
          this.sort.directive = -1

          let listBeforeSort = this.list
          this.list = listBeforeSort.sort((a,b) => {
            if(a[this.sort.field] > b[this.sort.field]) return -1
            else if(a[this.sort.field] < b[this.sort.field]) return 1
            else return 0
          })
        }
        else {
          console.log("초기화")
          //오름차순이 아니면 정렬을 초기화한다.
          this.sort.field = ''
          this.sort.directive = 0

          this.searchList().then()
        }
      } else {
        console.log("오름차순")
        //설정된게 없으면 오름차순부터
        this.sort.field = field
        this.sort.directive = 1


        let listBeforeSort = this.list
        this.list = listBeforeSort.sort((a,b) => {
          if(a[this.sort.field] > b[this.sort.field]) return 1
          else if(a[this.sort.field] < b[this.sort.field]) return -1
          else return 0
        })

      }


      //this.searchList().then()

    },


    openExplanBetlimit: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        let document = {}
        document.title = '최대배팅한도 설명'
        document.content = '해당기능은 게임사가 제공하는 게임내의 설정을 변경하는게 아닌,<br>맥스API 시스템상에서 배팅금액을 제한하는 기능입니다.<br><br>만약 게임사에서 정해진 배팅한도가 존재한다면<br>맥스API 시스템과 게임사 배팅한도는 중복으로 기능을 하게되며, 둘중 낮은금액이 최대배팅한도가 됩니다.'
        document.content += '<br><br><b><예시><br></b>게임내 배팅한도 : 1,000 ~ 5,000,000<br>맥스API의 최대배팅한도 : 3,000,000<br><br>위 환경에서 유저는 둘중 낮은금액인 3,000,000까지 배팅할수 있습니다.'

        document.content += '<br><br><br><b>※단, 제한금액계산은 한라운드의 배팅금 총합이 아니며 각 배팅 1회의 배팅금에 대하여 제한합니다.</b>'
        this.$refs['modal_explan_betlimit'].open(document)
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12" ref="contentWrapper">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-md-3 mb-1">
                            <div class="form-group">
                                <select-vendors @searchVendors="searchVendors"></select-vendors>
                            </div>
                        </div>

                        <div class="col-md-4">

                        </div>

                        <div class="col-md-2 mb-1">
                            <select class="form-select" v-model="searchType">
                                <option value="name_ko">{{ $t('게임명으로 검색(KO)') }}</option>
                                <option value="name_en">{{ $t('게임명으로 검색(EN)') }}</option>
                                <option value="code">{{ $t('CODE로 검색') }}</option>
                                <!--<option value="siteUsername" v-if="$store.getters['auth/user'].type === 'company'">{{ $t('파트너아이디') }}</option>-->
                            </select>
                        </div>
                        <div class="col-md-3 mb-1">
                            <div class="form-group">
                                <input type="text" class="form-control" :placeholder="$t('검색어')" v-model="searchText" />
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-0" style="min-height: 1000px;">
                        <table class="table align-middle table-nowrap">
                            <thead class="table-light">
                            <tr>
                                <th class="align-middle" v-if="!user && $store.getters['auth/user'].type === 'admin'">
                                    <a href="javascript:void(0)" @click="setSort('sort')">정렬&nbsp;
                                        <template v-if="sort.field === 'sort'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                                        <template v-else><span class="fa fa-sort"></span></template>
                                    </a>&nbsp;
                                </th>
                                <th class="align-middle">이미지</th>
                                <th scope="align-middle">
                                    <a href="javascript:void(0)" @click="setSort('ko')">KO&nbsp;
                                        <template v-if="sort.field === 'ko'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                                        <template v-else><span class="fa fa-sort"></span></template>
                                    </a>&nbsp;
                                </th>
                                <th scope="align-middle">
                                    <a href="javascript:void(0)" @click="setSort('en')">EN&nbsp;
                                        <template v-if="sort.field === 'en'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                                        <template v-else><span class="fa fa-sort"></span></template>
                                    </a>&nbsp;
                                </th>
                                <th class="align-middle">메모</th>
                                <th class="align-middle">사용</th>
                                <th class="align-middle">CODE</th>
                                <th class="align-middle">최대배팅한도&nbsp;&nbsp;<a href="javascript:void(0)" @click="openExplanBetlimit()"><i class="fas fa-question-circle"></i></a> </th>
                                <th class="align-middle">활성화</th>
                                <th class="align-middle">저장</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="bg-soft" v-for="game in list" :key="game._id" :class="{'bg-secondary': !game.active}" v-show="searchType == 'name_ko' ? game.names['ko'].toLowerCase().indexOf(searchText.toLowerCase()) > -1 : searchType == 'name_en' ? game.names['en'].toLowerCase().indexOf(searchText.toLowerCase()) > -1 : game.key.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ">
                                <td v-if="!user && $store.getters['auth/user'].type === 'admin'"><input type="number" class="form-control" v-model="game.sort" /></td>
                                <td>
                                    <img :src="game.image" class="img-thumbnail" style="width: 64px;" :title="game.names.ko" v-if="game.image" />
                                </td>
                                <td><input type="text" class="form-control" v-model="game.names.ko" :disabled="user || $store.getters['auth/user'].type !== 'admin'" /></td>
                                <td><input type="text" class="form-control" v-model="game.names.en" :disabled="user || $store.getters['auth/user'].type !== 'admin'" /></td>
                                <td><input type="text" class="form-control" v-model="game.memo" :disabled="user || $store.getters['auth/user'].type !== 'admin'" /></td>
                                <td>
                                    <span class="badge badge-pill badge-soft-success" v-if="game.isLobby===1 || game.categoryKey === 'slot'">가능</span>
                                </td>
                                <td>{{ game.key }}</td>
                                <td>
                                    <VueNumberFormat v-if="!game.key.startsWith('lobby')" class="form-control text-end" v-model:value="game.maxBetAmount" :options="{ precision: 0, prefix: '', suffix: '', decimal: '.', thousand: ',', acceptNegative: false, isInteger: true  }"></VueNumberFormat>
                                </td>
                                <td>
                                    <div class="form-group mb-1">
                                        <div class="form-check form-check-success form-switch form-switch-lg d-inline-block">
                                            <input class="form-check-input" type="checkbox" :id="'enable' + game._id" v-model="game.active">
                                            <label class="form-check-label" :for="'enable' + game._id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary btn-sm" @click="save(game)" v-b-tooltip.hover data-placement="top" title="저장"><span class="fa fa-save"></span></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

        <ModalAlert ref="modal_explan_betlimit" @searchList="searchList" />
    </div>
</template>
