<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  components: {

  },
  data() {
    return {
      document: null,
      openDialog: false,
    };
  },
  mounted() {

  },
  methods: {
    open: function(document) {
      this.document = document
      this.openDialog = true
    },
    searchList: function() {
      this.$emit('searchList')
    }
  }
};
</script>

<template>
  <div ref="contentWrapper" v-if="document">
    <b-modal
        :title="document.title"
        v-model="openDialog"
        size="xl"
        scrollable
        centered
        button-size="sm"
        hide-footer
    >
      <div class="d-block">
        <div style="min-height: 300px;" class="mb-3 ql-editor" v-html="document.content" v-if="document"></div>

        <div class="text-end">
          <hr />
          <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('확인') }}</button>
        </div>
      </div>
    </b-modal>

  </div>
</template>
